window.onload = function get_body() {
  const burger = document.getElementById('burger-button');

  function close_menu() {
    document.body.classList.toggle('locked');
    burger.classList.toggle('burger__button--open');
  }
  /*
  classList property returns the class name(s) of an element. It's read-only, but to modify use add() remove() and toggle() methods.
  */

  burger.addEventListener('click', function (e) {
    /*  event handler 'click' is attached to the "burger-button" element  */ e.preventDefault(); /*  the default action of the event will not be triggered when buger clicked */
    close_menu(); /*  then toggle the 'open' class, which closes the menu */
  });

  /*  Use the element.removeEventListener() method remove the 'click' event handlers from "burger-menu" in a more sophisticated design - Good practice  */
};
